<template>
  <div class="teaching-container">
    <div class="container-head">
      <div class="head-logo">
        <img alt="logo" src="~@/assets/image/logo-new.png" />
      </div>
      <div class="head-nav">
        <span class="head-class-name">{{ unitName }}</span>
        <div
          @click="handleStepClick(count)"
          v-for="count in totalStep"
          :key="count"
          :class="count === stepIndex ? 'step step-active' : 'step'"
        >
          {{ count }}
        </div>
      </div>
      <el-button
        @click="handleNextStepClick"
        :disabled="stepIndex === totalStep"
        round
        >下一步</el-button
      >
      <div style="position: absolute;right:2%;top:10px">
        <el-dropdown>
          <span class="el-dropdown-link">
            <el-avatar :size="40">
              <img :src="userAvatar"/>
            </el-avatar>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item><el-link @click="leaveConfirm">离开课堂</el-link></el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div
      class="container-content"
      :class="collapse ? '' : 'container-content-without-sider'"
    >
      <div class="content-sider">
        <!-- <div> -->
        <div class="sider-head" title="">{{ unitName }}</div>
        <div class="sider-content">
          <el-tabs v-model="activeTab" @tab-click="handleTabClick">
            <el-tab-pane label="01 课程引导" name="guide">
              <!-- <TeachingGuideCard v-for="(data,index) in guideList" :key="index" :data="data" /> -->
              <TeachingGuideCard v-if="guideList.length" :data="guideList[stepIndex - 1]" />
            </el-tab-pane>
            <el-tab-pane label="02 课程资料" name="data">
              <TeachingDataCard
                v-for="(data, index) in resourceList"
                :key="index"
                :data="data"
                :urlParam="urlParam"
              />
              <Empty
                v-show="resourceList.length === 0"
                description="暂无课程资料"
              />
            </el-tab-pane>
            <!-- <el-tab-pane
              label="03 课后练习"
              name="practice"
            >
              <PracticeQuestionList
                :unitId="unitId"
                :hasGetData="hasGetData.practice"
              />
            </el-tab-pane> -->
          </el-tabs>
        </div>
        <!-- </div> -->
        <div class="collapse-change" @click="handleCollapseChage">
          <div :class="collapse ? 'icon-close' : 'icon-open'" />
        </div>
      </div>
      <div class="content-main">
        <iframe
        ref="innerIframe"
          :src="iframeSrc"
          style="width: 100%; height: 100%; background: #fff"
          id="coding"
        />
      </div>
      <div
        v-viewer="imgViewOptions"
        class="images clearfix"
        style="display: none"
      >
        <img :src="imgSrc" :data-source="imgSrc" class="image" />
      </div>
    </div>
  </div>
</template>

<script>
import { getCourseGuide, getCourseResource,getCourseTime,getCourseDetail,sendStudyTimes } from '@/api/course';
import TeachingGuideCard from '@/components/teaching/TeachingGuideCard.vue';
import TeachingDataCard from '@/components/teaching/TeachingDataCard.vue';
import PracticeQuestionList from '@/components/teaching/PracticeQuestionList.vue';
import Empty from '@/components/modules/Empty.vue';
import Vue from 'vue';
import { ACCESS_TOKEN,GO_BACK_URL } from '@/store/mutation-types';
import { Message } from 'element-ui';
import userAvatar1 from '@/assets/image/avatar/UserAvatar1.png'
import userAvatar2 from '@/assets/image/avatar/UserAvatar2.png'
import userAvatar3 from '@/assets/image/avatar/UserAvatar3.png'
import userAvatar4 from '@/assets/image/avatar/UserAvatar4.png'
import userAvatar5 from '@/assets/image/avatar/UserAvatar5.png'
import userAvatar6 from '@/assets/image/avatar/UserAvatar6.png'
const STUDENT = 1;
const TEACHER = 2;
// const mfxUrlDev = 'https://www-stage.mofaxiao.com/stu/choiceOrg';
// const mfxUrl = 'https://www.mofaxiao.com';
export default {
  data() {
    return {
      goBackUrl:null,
      urlParam:{
        auth:null,
        userId:null,
        examAnswerId:null
      },
      userAvatarImgs:[
        userAvatar1,
        userAvatar2,
        userAvatar3,
        userAvatar4,
        userAvatar5,
        userAvatar6,
      ],
      userAvatar:null,
      lab:null,
      activeTab: 'guide',
      userId:null,
      lessonId:null,
      lessonStartAt:null,
      lessonEndAt:null,
      liveOrgLessonId:null,
      schoolId:null,
      userStudyTimeId:null,
      iframeSrc: '',
      unitId: '',
      unitName: '',
      collapse: true,
      totalStep: 0,
      stepIndex: 1,
      guideList: [],
      imgSrc: 'http://localhost:8080/img/match-bg.81a37cf9.png',
      imgViewOptions: {
        toolbar: true,
        url: 'data-source',
      },
      resourceList: [],
      hasGetData: {data: false,practice: false} //是否请求数据
    };
  },
  components: {
    TeachingGuideCard,
    TeachingDataCard,
    PracticeQuestionList,
    Empty,
  },
  created: function () {
    this.userId = this.$route.query.user_id;
    this.lessonId = this.$route.query.lesson_id;
    this.schoolId = this.$route.query.school_id;
    this.liveOrgLessonId = this.$route.query.live_org_lesson_id;
    this.lessonStartAt = this.$route.query.lesson_start_at;
    this.lessonEndAt = this.$route.query.lesson_end_at;
    const userToken = this.$route.query.user_token;
    const auth = this.$route.query.auth;
    const routerTo = this.$route.path;
    if(!this.lessonId || !auth || !this.userId){
          this.$notify({
            title: '系统提示',
            message: '资源未找到！',
            type: 'warning'
        });
    }
    const token = Vue.ls.get(ACCESS_TOKEN);
    if (!token) {
      location = `/courseTransfer?routerTo=${routerTo}&user_id=${this.userId}&user_token=${userToken}&school_id=${this.schoolId}&lesson_id=${this.lessonId}&auth=${auth}&live_org_lesson_id=${this.liveOrgLessonId}&lesson_start_at=${this.lessonStartAt}&lesson_end_at=${this.lessonEndAt}`
    }
    this.goBackUrl = Vue.ls.get(GO_BACK_URL);
    this.urlParam.auth = auth;
    this.urlParam.userId = this.userId;
    auth == TEACHER ? this.urlParam.examAnswerId = 'mfx':'';
    let param = {
      auth:auth,
    }
    this.getRandomAvator();
    this.handleLessionDetail(param);
  },
  mounted(){
    this.getUserStudyTime();
    this.timer = window.setInterval(() => {
        setTimeout(() => {
            this.getUserStudyTime()
        },0)
    },60000)
  },
  updated: function () {
    const imgEles = document.querySelectorAll('.teaching-guide-card .text img');
    for (let index = 0; index < imgEles.length; index++) {
      const element = imgEles[index];
      element.style.cursor = 'pointer';
      element.style.width = 'auto';
      element.style.height = 'auto';
      element.style.maxWidth = '100%';
      element.style.maxHeight = '100%';
      element.title = '点击放大查看';
      element.addEventListener('click', (e) => {
        this.imgSrc = e.target.src;
        this.show();
      });
    }
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  methods: {
    handleLessionDetail(param){
      let _this = this;
      getCourseDetail({lesson_id:this.lessonId}).then( res => {
          if(res.code == 200){
            let auth = param.auth;
              const {studyLink,unitName} = res.result;
              _this.unitName = unitName;
              if(studyLink){
                  const links = studyLink.split("?");
                  if(links && links.length > 0){
                      let unitIdArray = "";
                      let labString = links[0];
                      _this.lab = labString.substring(1,labString.length);
                      unitIdArray = links[1];
                      _this.unitId = unitIdArray.split("=")[1];
                  }
              }
              let examAnswerId = '';
              if(auth == TEACHER){
                examAnswerId = 'mfx';
              }
              let route = ``;
              if (_this.lab === 'scratch3') {
                route = `/${_this.lab}?unitId=${_this.unitId}&examAnswerId=${examAnswerId}&auth=${auth}&user_id=${_this.userId}`;
              } else {
                route = `/coding?lab=${_this.lab}?unitId=${_this.unitId}&examAnswerId=${examAnswerId}&auth=${auth}&user_id=${_this.userId}`;
              }
              _this.iframeSrc =
                window.location.host.indexOf('localhost') === -1
                  ? `${window.location.protocol}//${window.location.host}${route}`
                  : `https://www.eqibian.com${route}`;
              _this.$refs.innerIframe.click();
              const params = { courseUnitId: _this.unitId };
              getCourseGuide(params)
                .then((res2) => {
                  //获取失败
                  if(!res2.success){
                    _this.$message.error(res2.message || "获取课程数据失败");
                    return;
                  }
                    const { result = [] } = res2;
                    _this.guideList = result;
                    _this.totalStep = (result && result.length) || 0;
                })
                .catch((err) => {});
          }else{
            _this.$message.error("获取课程信息失败");
          }
      })
  },
    addClassWithImg(htmlContent) {
      let r = RegExp(/<[img]+\s+(.*?)(?<id>\w*?)[\s'"](.*?)>/g);
      let matchres = htmlContent.match(r); //返回数组['<img ... />','<img ... />']
      if (matchres) {
        matchres.forEach((item, index) => {
          let len = item.length;
          let _str =
            item.slice(0, len - 2) +
            ' style="width:60%;height:200px;text-align:center;margin:0 20%;"/>'; //追加之后的img
          htmlContent = htmlContent.replace(item, _str);
        });
      }
      const resultHtml = parseHtml(htmlContent);
      return resultHtml;
    },
    show() {
      const viewer = this.$el.querySelector('.images').$viewer;
      viewer.show();
    },
    onImgClick(e) {},
    handleNextStepClick() {
      this.handleStepClick(this.stepIndex + 1);
    },
    handleStepClick(step) {
      this.activeTab = 'guide';
      this.stepIndex = step;
      const scrollEle = document.getElementsByClassName('el-tabs__content')[0];
      const guideList = document.getElementById('pane-guide').children;
      scrollEle.scrollTop = 0; //guideList[this.stepIndex - 1].offsetTop;
    },
    handleTabClick(tab, event) {
      if (tab.name === 'data'&&!this.hasGetData['data']) {
       getCourseResource({ courseUnitId: this.unitId }).then((res) => {
          //获取失败
          if(!res.success){
            this.$message.error(res.message || "获取课程数据失败");
            return;
          }
          const result = res.result || [];
          this.resourceList = result;
          this.hasGetData['data'] = true;
        });
      } else if (tab.name === 'practice'&&!this.hasGetData['practice']) {
        this.hasGetData['practice'] = true;
      }
    },
    handleCollapseChage() {
      this.collapse = !this.collapse;
    },
    handleSubmitClick() {},
    /**
     * 离开课堂按钮
     */
    leaveConfirm(){
      let _this = this;
      this.$confirm('确定要离开课堂吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {
          id:_this.userStudyTimeId,
          user_id:_this.userId,
          lesson_id:_this.lessonId,
          lesson_start_at:_this.lessonStartAt
        };
        getCourseTime(param).then( res => {
           _this.closeWindows();
        })
      }).catch(() => {});
    },
    /**
     * 获取学生累计学习时间
     */
    getUserStudyTime(){
      console.log("轮询！！");
      let _this = this;
      let param = {
        id:this.userStudyTimeId,
        user_id:this.userId,
        lesson_id:this.lessonId,
        lesson_start_at:this.lessonStartAt,
        lesson_end_at:this.lessonEndAt,
        school_id:this.schoolId,
        live_org_lesson_id:this.liveOrgLessonId
      };
      getCourseTime(param).then( res => {
        if(res.code == 4000 || res.code == 200){
          let data = res.result.data;
          _this.userStudyTimeId = data.id;
          if(res.code == 4000){
            let iframeSrcTemp = `${_this.iframeSrc}&lesson_end=1`;
            _this.iframeSrc = iframeSrcTemp;
            _this.closeWindows();
            // let paramSendTime = {
            //   lesson_id:_this.lessonId,
            //   lesson_start_at:_this.lessonStartAt,
            //   live_org_lesson_id:_this.liveOrgLessonId,
            //   school_id:_this.schoolId
            // };
          //   sendStudyTimes(paramSendTime).then( res2 => {
          //     _this.$alert("您已经连续上课120分钟，请注意休息", '', {
          //     confirmButtonText: '确定',
          //     callback: action => {
          //       _this.closeWindows();
          //     }
          //   });
          // })
        }else if(res.code == 200){
          let iframeSrcTemp = `${_this.iframeSrc}&lesson_end=0`;
            _this.iframeSrc = iframeSrcTemp;
        }
        }else if(res.code == 500){
          _this.$message.error(res.message);
        }
      })
    },
    /**
     * 随机头像
     */
    getRandomAvator () {
      let num = Math.ceil(Math.random()*5); 
      this.userAvatar = this.userAvatarImgs[num];
    },
    /**
     * 跳回到魔法校
     */
    closeWindows() {
      location.href = this.goBackUrl;
    }
  },
  destroyed() {
    window.clearInterval(this.timer)
  }
};
</script>
<style scoped>
.sider-content /deep/.el-tabs__nav{
  width: 100% !important;
}
.sider-content /deep/.el-tabs__item {
  padding: 0 12px;
  font-weight: bold;
  width: 50% !important;
  text-align: center;
}
.sider-content /deep/ .el-tabs__item:hover {
  color: #409eff;
}
.sider-content /deep/ .el-tabs__item.is-active {
  color: #409eff;
}
.sider-content /deep/ .el-tabs__active-bar {
  background-color: #409eff;
  height: 4px;
}
.sider-content /deep/ .el-tabs {
  height: 100%;
}
/* .sider-content /deep/ .el-tabs{
        overflow: auto;
        height: calc(100% - 57px);
    } */
.head-logo{
  position: absolute;
  left: 18px;
}
.head-logo img{
  width: 100px;
}
</style>
<style lang="scss" scoped>
@import '@/assets/css/views/teaching.scss';
</style>
